import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Contentpage from "../components/templates/Contentpage"
import Seo from '../components/atoms/Seo'
import Row from "../components/molecules/Row"
import Column from "../components/atoms/Column"
import Title from "../components/atoms/Title"
import Divider from "../components/atoms/Divider"

// markup
const AttorneysPage = () => {
  return (
    <Contentpage>
      <Seo
      title='Attorneys'
      description='Attorneys, David Pedley'
      />
      
      <Divider />

      <Row>
        <Column>
            <div style={{marginBottom: '16px'}}>
              <StaticImage src='../images/attorneys/david_pedley.jpeg' alt='David Pedley'/>
            </div>
        </Column>
        <Column>
            <Title level={3} position='left' color='#de5827'>David M. Pedley</Title>
            
            <hr />
            <Divider />

            <p>
              David Pedley’s practice focuses on corporate and securities law,
              including mergers and acquisitions, and venture capital. 
              David has been involved in securities and mergers and acquisition
              transactions valued in aggregate at well over $1 billion. 
              David has represented startups, technology companies,
              venture capital and debt funds, merchant banks,
              and one of the world’s largest hedge funds.
              His clients have included businesses in industries as diverse as IPTV,
              digital marketing, clinical research, for-profit education, education technology and satellite services.
              Previously, David was a shareholder in Greenberg Traurig and
              an associate at the New York office of LeBoeuf, Lamb, Greene & MacRae (subsequently part of Dewey & LeBouef).
              David is admitted to practice law in Connecticut,
              Colorado, Georgia, Kentucky, New Hampshire and New York.
              David obtained a B.A.  from the University of 
              Pennsylvania in 1987 and his J.D. (with distinction)
              from the Emory University School of Law in 1991. 
              David has an AV rating from Martindale Hubbell.
            </p>
        </Column>
      </Row>
    </Contentpage>
  )
}

export default AttorneysPage
